import React from "react";
import { Link } from "react-router-dom";
import pickup_img from "../assets/pickup_img.png";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { FaComputer } from "react-icons/fa6";
import { GiThreeLeaves } from "react-icons/gi";
import { TbUserStar } from "react-icons/tb";

const services = [
  {
    title: "Express Parcel Services",
    description: "Get your urgent shipments delivered quickly and reliably.",
    icon: "📦",
    link: "/Express",
  },
  {
    title: "Ecommerce Shipping Solutions",
    description:
      "Streamline your online store's fulfillment process with our hassle-free shipping options.",
    icon: "🛒",
    link: "/Ecommerce",
  },
  {
    title: "Import & Export Shipping Management",
    description:
      "Navigate the complexities of international trade with our expert guidance.",
    icon: "🌍",
    link: "/ExportImp",
  },
  {
    title: "Less-than-Truckload (LTL)",
    description: "We offer cost-effective solutions for freight of all sizes.",
    icon: "🚛",
    link: "/Truckload",
  },
  {
    title: "Train Transport Services",
    description:
      "Benefit from the reliability and affordability of rail shipping for large shipments.",
    icon: "🚂",
    link: "/Traincargo",
  },
  {
    title: "Full-Truckload (FTL) Shipping",
    description: "We offer cost-effective solutions for freight of all sizes.",
    icon: "🚛",
    link: "/Truckload",
  },
];

const Aboutus = () => {
  return (
    <>
      <div className="-z-50 relative px-10 mt-16 mb-10 w-full max-w-screen-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200">
        <div className="text-sky-600 text-5xl font-semibold text-center">
          About Us
        </div>
        <div className="card bg-gray-100 shadow-lg rounded-lg mt-8 transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
          <p className="m-4 text-gray-800 font-semibold p-4 text-justify">
            Established in <strong>2020 </strong>with a vision to transform the
            logistics and shipping industry,<strong> PICKUPXPRESS</strong> has
            quickly grown into a reliable and comprehensive transportation
            provider. Starting with an initial paid-up capital of ₹1 lakh and
            guided by the expertise and vision of our three directors, we have
            expanded our services to meet the diverse shipping needs of
            businesses across various sectors. Our commitment to excellence,
            efficiency, and customer satisfaction has positioned us as a trusted
            name in the logistics field.
          </p>
        </div>

        <div
          className="w-full mt-4 max-w-screen-xl mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden flex 
    flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200"
        >
          <div className="bg-white py-10 ">
            <div className="container mx-auto px-4">
              <h1 className="text-4xl font-bold text-center mb-8">
                Our Services{" "}
              </h1>
              <p className="text-center mb-4 font-semibold">
                At PICKUPXPRESS, we offer a wide range of shipping and logistics
                services tailored to our clients' specific requirements:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {services.map((service, index) => (
                  <div
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                  >
                    <div className="text-4xl text-cyan-600 mb-4">
                      {service.icon}
                    </div>
                    <h2 className="text-xl font-semibold mb-2">
                      {service.title}
                    </h2>
                    <p className="text-gray-700">{service.description}</p>
                    <div className="mt-2">
                      <Link
                        to={service.link}
                        className="mt-4 text-cyan-600 hover:text-cyan-800 transition-colors duration-300"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white shadow-lg rounded-lg mt-10 transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
          <h3 className="font-bold  text-4xl text-center mt-3">Our Journey</h3>
          <p className="m-4 text-gray-800 font-semibold p-4 text-justify">
            PICKUPXPRESS started its journey in 2020 with a modest capital of ₹1
            lakh and the vision of three directors who shared a common goal: to
            create a logistics company that offers innovative, customer-centric
            solutions. Despite our humble beginnings, we have rapidly grown by
            leveraging our industry expertise, embracing technology, and
            focusing on building strong customer relationships.
          </p>
        </div>

        <div className="card bg-white shadow-lg rounded-lg mt-16 transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
          <h3 className="font-bold text-sky-600  text-4xl text-center mt-3">
            Our Commitment
          </h3>
          <ul className="p-4">
            <div className="w-full flex items-center justify-center">
              <li className="text-white p-4 text-sm bg-sky-600 shadow-lg rounded-lg mt-10 w-3/12 text-center">
                <div className="flex flex-col items-center">
                  <div className="flex items-center justify-center w-8 h-8 text-4xl mb-2">
                    <FaPeopleGroup />
                  </div>
                  <strong className="text-base">
                    Customer-Centric Approach:
                  </strong>
                  <p className="mt-3">
                    We work closely with our clients to understand their unique
                    shipping needs and provide tailored solutions that align
                    with their business objectives.
                  </p>
                </div>
              </li>
            </div>
            <div className="w-full flex items-center justify-center gap-96">
              <li className="text-white p-4 text-sm bg-sky-600 shadow-lg rounded-lg mt-8 w-3/12 text-center">
                <div className="flex flex-col items-center">
                  <div className="flex items-center justify-center w-8 h-8 text-4xl mb-2">
                    <IoShieldCheckmarkSharp />
                  </div>
                  <strong className="text-base">
                    Reliability and Efficiency:{" "}
                  </strong>
                  <p className="mt-3">
                    Whether it's a full truckload or a single parcel, we handle
                    every shipment with the utmost care, ensuring timely and
                    secure delivery.
                  </p>
                </div>
              </li>

              <li className="text-white p-4 text-sm bg-sky-600 shadow-lg rounded-lg mt-10 w-3/12 text-center">
                <div className="flex flex-col items-center">
                  <div className="flex items-center justify-center w-8 h-8 text-4xl mb-2">
                    <FaComputer />
                  </div>
                  <strong className="text-base">Technology-Driven: </strong>
                  <p className="mt-3">
                    We utilize advanced technology to streamline our operations,
                    from real-time tracking to automated logistics processes.
                    Thus, we are transparent, efficient, and have control over
                    the shipments.
                  </p>
                </div>
              </li>
            </div>
            <div className="w-full flex items-center justify-center gap-36 pb-10">
              <li className="text-white p-4 text-sm bg-sky-600 shadow-lg rounded-lg mt-16 w-3/12 text-center">
                <div className="flex flex-col items-center">
                  <div className="flex items-center justify-center w-8 h-8 text-4xl mb-2">
                    <GiThreeLeaves />
                  </div>
                  <strong className="text-base">Sustainability: </strong>
                  <p className="mt-3">
                    We are mindful of our environmental impact and strive to
                    offer sustainable shipping solutions.
                  </p>
                </div>
              </li>

              <li className="text-white p-4 text-sm bg-sky-600 shadow-lg rounded-lg mt-16 w-3/12 text-center">
                <div className="flex flex-col items-center">
                  <div className="flex items-center justify-center w-8 h-8 text-4xl mb-2">
                    <TbUserStar />
                  </div>
                  <strong className="text-base">Experienced Team </strong>
                  <p className="mt-3">
                    Our team comprises skilled logistics professionals who bring
                    expertise and dedication to every aspect of our services.
                  </p>
                </div>
              </li>
            </div>
          </ul>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 ">
          <div className="card bg-white shadow-lg rounded-lg my-10 transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]  w-full">
            <h3 className="font-semibold text-sky-600 text-4xl text-center mt-3">
              Looking Ahead
            </h3>
            <p className="m-4 text-gray-800 font-semibold p-4 text-justify">
              As we continue to grow and expand our services, our focus remains
              on delivering top-notch logistics solutions that adapt to the
              evolving needs of the market. Whether it's domestic transport,
              e-commerce shipping, or international freight, PICKUPXPRESS is
              dedicated to being a partner that businesses can rely on for all
              their shipping needs.
            </p>
          </div>
          <div className="flex items-center justify-center my-10 w-full h-80 ">
            <img
              src={pickup_img}
              alt="Express Parcels"
              className="max-w-full max-h-full p-2 rounded-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
