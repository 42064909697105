import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base_Url, priceEstimator, getpincode } from '../Config/config';

const rateCalculatorSchema = z.object({
  pickupPincode: z.string()
    .length(6, "Pickup Pincode must be exactly 6 digits")
    .regex(/^\d+$/, "Pickup pincode must be numeric"),
  dropPincode: z.string()
    .length(6, "Drop Pincode must be exactly 6 digits")
    .regex(/^\d+$/, "Drop Pincode must be numeric"),
  weight: z.string()
    .min(1, "Weight is required")
    .regex(/^\d*\.?\d+$/, "Weight must be a positive number and can be a float"),
  length: z.string()
    .min(1, "Length is required")
    .regex(/^\d*\.?\d+$/, "Length must be a positive number and can be a float"),
  width: z.string()
    .min(1, "Width is required")
    .regex(/^\d*\.?\d+$/, "Width must be a positive number and can be a float"),
  height: z.string()
    .min(1, "Height is required")
    .regex(/^\d*\.?\d+$/, "Height must be a positive number and can be a float"),
  shipmentType: z.string().optional(),
  declaredValue: z
    .string()
    .optional()
    .refine(value => value === "" || (!isNaN(Number(value)) && Number(value) >= 0), {
      message: "Declared Value must be a non-negative number",
    }),
  prepaid: z.string().nonempty({ message: "Prepaid/COD is required" }),
  cod: z.string().optional(),
});

const RateCalculator = () => {
  const [data, setData] = useState(null);
  const [prepaidValue, setPrepaidValue] = useState('');
  const [loader, setLoader] = useState(false);
  const [pickupCity, setPickupCity] = useState('');
  const [dropCity, setDropCity] = useState('');

  const { register, handleSubmit, formState: { errors }, reset, watch, setValue, clearErrors } = useForm({
    resolver: zodResolver(rateCalculatorSchema),
  });

  const handlePrepaidChange = (e) => {
    setPrepaidValue(e.target.value);
  };

  const handlePincodeChange = (e, type) => {
    const { value } = e.target;
    const filteredValue = value.replace(/[^0-9]/g, '');
    setValue(type === 'pickup' ? 'pickupPincode' : 'dropPincode', filteredValue);
    if (filteredValue.length === 6) {
      clearErrors(type === 'pickup' ? 'pickupPincode' : 'dropPincode');
    }

    // Clear city whenever the pincode changes
    clearCity(type);

    if (filteredValue.length === 6) {
      fetchPincodeData(filteredValue, type);
    }
  };

  const onSubmit = async (data) => {
    setLoader(true);
    const requestBody = {
      from_pincode: data.pickupPincode,
      to_pincode: data.dropPincode,
      length: parseFloat(data.length),
      width: parseFloat(data.width),
      height: parseFloat(data.height),
      weight: parseFloat(data.weight) / 1000, // Convert to kg
      shipment_type: data.shipmentType,
      declared_value: data.declaredValue ? parseFloat(data.declaredValue) : "",
      payment_mode: data.prepaid,
      cod_amount: data.prepaid === 'cod' ? parseFloat(data.cod) : "",
    };

    try {
      const response = await axios.post(Base_Url + priceEstimator, requestBody, {
        // headers: {
        //   token: localStorage.getItem("token") || "eyJ1c2VyX2lkIjoiNyIsImV4cGlyZXNfYXQiOjE3Mjc1MTc2Nzd9",
        //   cus_id: localStorage.getItem("cus_id") || "7",
        //   "Content-Type": "application/json",
        // },
      });

      if (response.data.status === "success") {
        setData(response.data);
        reset();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  const clearCity = (type) => {
    if (type === 'pickup') {
      setPickupCity('');
    } else {
      setDropCity('');
    }
  };

  const pickupPincode = watch('pickupPincode');
  const dropPincode = watch('dropPincode');

  useEffect(() => {
    if (pickupPincode && pickupPincode.length === 6) {
      fetchPincodeData(pickupPincode, 'pickup');
    } else {
      clearCity('pickup');
    }
  }, [pickupPincode]);

  useEffect(() => {
    if (dropPincode && dropPincode.length === 6) {
      fetchPincodeData(dropPincode, 'drop');
    } else {
      clearCity('drop');
    }
  }, [dropPincode]);

  const fetchPincodeData = async (pincode, type) => {
    const urlgetPincode = `${Base_Url}${getpincode}?pincode=${pincode}`;
    try {
      const response = await fetch(urlgetPincode, {
        method: 'GET',
        headers: {
          token: localStorage.getItem("token") || "eyJ1c2VyX2lkIjoiNyIsImV4cGlyZXNfYXQiOjE3Mjc1MTc2Nzd9",
          cus_id: localStorage.getItem("cus_id") || "7",
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data && data.status === "SUCCESS") {
        let { city } = data.data[0];
        city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
        if (type === 'pickup') {
          setPickupCity(city);
        } else {
          setDropCity(city);
        }
      } else {
        clearCity(type);
        toast.error("Failed to fetch city data");
      }
    } catch (error) {
      console.error('Error fetching pincode data:', error);
      toast.error("Error fetching pincode data");
    }
  };

  return (
    <div className="container mx-auto p-4 relative">
      <div className="block w-full max-w-screen-xl px-4 mx-auto bg-white border shadow-lg rounded-xl border-white/80 bg-opacity-90 backdrop-blur-2xl backdrop-saturate-200 lg:px-8 py-6">
        <div className="container p-4 relative grid grid-cols-1 lg:grid-cols-2 gap-10">
          {/* Rate Calculator Form */}
          <div className="w-full text-gray-800 p-6 shadow-lg bg-gray-50 rounded-lg transition-all duration-300 hover:shadow-xl">
            <h2 className="text-2xl font-bold mb-6 text-sky-600 flex items-center justify-center">
              Rate Calculator
            </h2>

            <form className="space-y-6 font-medium" onSubmit={handleSubmit(onSubmit)}>
              {/* Pickup and Drop Pincode */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="pickupPincode" className="block text-sm mb-1">
                    Pickup Pincode <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="6"
                    id="pickupPincode"
                    className={`w-full px-3 py-2 border ${errors.pickupPincode ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                    {...register('pickupPincode')}
                    onChange={(e) => handlePincodeChange(e, 'pickup')}
                  />
                  {errors.pickupPincode ? (
                    <p className="text-red-500 text-sm mt-1">{errors.pickupPincode.message}</p>
                  ) : (
                    pickupCity && <p className="text-sm text-sky-600 mt-2 font-medium">Pickup City: {pickupCity}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="dropPincode" className="block text-sm mb-1">
                    Drop Pincode <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="dropPincode"
                    maxLength="6"
                    className={`w-full px-3 py-2 border ${errors.dropPincode ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                    {...register('dropPincode')}
                    onChange={(e) => handlePincodeChange(e, 'drop')}
                  />
                  {errors.dropPincode ? (
                    <p className="text-red-500 text-sm mt-1">{errors.dropPincode.message}</p>
                  ) : (
                    dropCity && <p className="text-sm text-sky-600 mt-2 font-medium">Drop City: {dropCity}</p>
                  )}
                </div>
              </div>

              {/* Dimensions and Weight */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm mb-1">
                    Dimensions (in Cms) <span className="text-red-600">*</span>
                  </label>
                  <div className="grid grid-cols-3 gap-2">
                    <div>
                      <input
                        type="number"
                        id="length"
                        step="0.01"
                        className={`w-full px-3 py-2 border ${errors.length ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                        placeholder="L"
                        {...register('length')}
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        id="width"
                        step="0.01"
                        className={`w-full px-3 py-2 border ${errors.width ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                        placeholder="W"
                        {...register('width')}
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        id="height"
                        step="0.01"
                        className={`w-full px-3 py-2 border ${errors.height ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                        placeholder="H"
                        {...register('height')}
                      />
                    </div>
                  </div>
                  {errors.length && <p className="text-red-500 text-sm mt-1">{errors.length.message}</p>}
                  {errors.width && <p className="text-red-500 text-sm mt-1">{errors.width.message}</p>}
                  {errors.height && <p className="text-red-500 text-sm mt-1">{errors.height.message}</p>}
                </div>

                <div>
                  <label htmlFor="weight" className="block text-sm mb-1">
                    Weight (in Grms) <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    id="weight"
                    step="0.01"
                    className={`w-full px-3 py-2 border ${errors.weight ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                    {...register('weight')}
                  />
                  {errors.weight && <p className="text-red-500 text-sm mt-1">{errors.weight.message}</p>}
                </div>
              </div>

              {/* Shipment Type and Prepaid/COD */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="shipmentType" className="block text-sm mb-1">
                    Shipment Type <span className="text-gray-500">(Optional)</span>
                  </label>
                  <select
                    id="shipmentType"
                    className="w-full px-3 py-2 border border-gray-300 shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition bg-white"
                    {...register('shipmentType')}
                  >
                    <option value="">Select Shipment Type</option>
                    <option value="Forward">Forward</option>
                    {/* <option value="Express">Express</option> */}
                  </select>
                </div>

                <div>
                  <label htmlFor="prepaid" className="block text-sm mb-1">
                    Prepaid/COD <span className="text-red-600">*</span>
                  </label>
                  <select
                    id="prepaid"
                    className={`w-full px-3 py-2 border ${errors.prepaid ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition bg-white`}
                    {...register('prepaid')}
                    onChange={handlePrepaidChange}
                  >
                    <option value="">Select Prepaid/COD</option>
                    <option value="prepaid">Prepaid</option>
                    <option value="cod">COD</option>
                  </select>
                  {errors.prepaid && <p className="text-red-500 text-sm mt-1">{errors.prepaid.message}</p>}
                </div>
              </div>

              {/* Declared Value and COD Amount */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="declaredValue" className="block text-sm mb-1">
                    Declared Value <span className="text-gray-500">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    id="declaredValue"
                    className={`w-full px-3 py-2 border ${errors.declaredValue ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                    {...register('declaredValue')}
                  />
                  {errors.declaredValue && <p className="text-red-500 text-sm mt-1">{errors.declaredValue.message}</p>}
                </div>

                {prepaidValue === 'cod' && (
                  <div>
                    <label htmlFor="cod" className="block text-sm mb-1">
                      COD Amount <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="cod"
                      className={`w-full px-3 py-2 border ${errors.cod ? 'border-red-500' : 'border-gray-300'} shadow-md rounded-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition`}
                      {...register('cod')}
                    />
                    {errors.cod && <p className="text-red-500 text-sm mt-1">{errors.cod.message}</p>}
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div className="w-full flex justify-center">
                <button
                  type="submit"
                  className="bg-sky-600 text-white px-6 py-2 rounded-lg hover:bg-sky-500 transition duration-300 shadow-md hover:shadow-lg font-medium flex items-center justify-center"
                  disabled={loader}
                >
                  {loader ? (
                    <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                  ) : null}
                  {loader ? "Calculating..." : "Calculate Rate"}
                </button>
              </div>
            </form>
          </div>

          {data && (
            <div className="overflow-x-auto bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-sky-600 mb-4">Rate Calculation Results</h3>
              <table className="min-w-full bg-white rounded-lg overflow-hidden">
                <thead className="bg-sky-600 text-white">
                  <tr>
                    <th className="px-4 py-2 text-left">From Pincode</th>
                    <th className="px-4 py-2 text-left">To Pincode</th>
                    <th className="px-4 py-2 text-left">Zone</th>
                    <th className="px-4 py-2 text-left">Weight (kg)</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  <tr className="border-b">
                    <td className="px-4 py-2">{data.data.from_pincode}</td>
                    <td className="px-4 py-2">{data.data.to_pincode}</td>
                    <td className="px-4 py-2">{data.data.zone}</td>
                    <td className="px-4 py-2">{data.data.weight}</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="text-lg font-semibold text-sky-600 mt-6 mb-2">Pricing Information</h4>
              <table className="min-w-full bg-white rounded-lg overflow-hidden">
                <thead className="bg-sky-600 text-white">
                  <tr>
                    <th className="px-4 py-2 text-left">Carrier</th>
                    <th className="px-4 py-2 text-left">Service Type</th>
                    <th className="px-4 py-2 text-left">Base Price</th>
                    <th className="px-4 py-2 text-left">Additional Price</th>
                    <th className="px-4 py-2 text-left">COD Extra</th>
                    <th className="px-4 py-2 text-left">Total Price</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {Object.entries(data.data.partners_pricing).map(([partnerName, services]) =>
                    Object.entries(services).map(([serviceType, pricing]) => (
                      <tr key={`${partnerName}-${serviceType}`} className="border-b hover:bg-gray-100 transition duration-300">
                        <td className="px-4 py-2 font-medium">{partnerName}</td>
                        <td className="px-4 py-2">{serviceType}</td>
                        <td className="px-4 py-2">₹{pricing.base_price}</td>
                        <td className="px-4 py-2">₹{pricing.additional_price}</td>
                        <td className="px-4 py-2">₹{pricing.cod_extra}</td>
                        <td className="px-4 py-2 font-bold text-sky-600">
                          ₹{data.data.partners_pricing[partnerName][serviceType].cod_total_price}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RateCalculator;