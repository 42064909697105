// import React, { useState } from 'react';
// import { RxCross2 } from "react-icons/rx";
// import {
//     Package,
//     MapPin,
//     Clock,
//     Navigation,
//     CheckCircle,
//     ChevronRight,
//     ChevronLeft
// } from 'lucide-react';

// const TrackShipment = ({ isOpen, onClose, trackData }) => {
//     const [activeDetailTab, setActiveDetailTab] = useState(0);

//     if (!isOpen || !trackData) return null;

//     const shipment = trackData;
//     const latestScan = shipment.Scans[shipment.Scans.length - 1].ScanDetail;

//     const formatDate = (dateString) => {
//         if (!dateString) return 'Not available';
//         return new Date(dateString).toLocaleString('en-US', {
//             year: 'numeric',
//             month: 'long',
//             day: 'numeric',
//             hour: '2-digit',
//             minute: '2-digit'
//         });
//     };

//     const progressStages = [
//         'Shipped',
//         'In Transit',
//         'Out for Delivery',
//         'Delivered'
//     ];

//     const getCurrentStageIndex = (currentStatus) => {
//         const statusMap = {
//             'Shipped': 0,
//             'In Transit': 1,
//             'Out for Delivery': 2,
//             'Delivered': 3
//         };
//         return statusMap[currentStatus] || 0;
//     };

//     const currentStageIndex = getCurrentStageIndex(latestScan.Scan);

//     return (
//         <div className="fixed inset-0 bg-black/60 flex items-center justify-center p-4 backdrop-blur-sm z-50">
//             <div className="bg-white w-full max-w-5xl rounded-2xl shadow-2xl overflow-hidden grid grid-cols-3 max-h-[90vh]">
//                 {/* Sidebar Navigation */}
//                 <div className="bg-gradient-to-b from-blue-600 to-blue-800 text-white p-6 col-span-1 flex flex-col">
//                     <div className="mb-8">
//                         <button
//                             onClick={onClose}
//                             className="absolute top-4 right-4"
//                         >
//                             <RxCross2 size={24} className="text-black" />
//                         </button>
//                         <Package size={48} className="mb-4" />
//                         <h2 className="text-2xl font-bold">Shipment Tracking</h2>
//                         <p className="text-sm opacity-75">AWB: {shipment.AWB}</p>
//                     </div>

//                     <div className="space-y-4 flex-grow">
//                         {['Shipment', 'Recipient', 'Timeline'].map((tab, index) => (
//                             <button
//                                 key={tab}
//                                 onClick={() => setActiveDetailTab(index)}
//                                 className={`w-full text-left p-3 rounded-lg flex items-center justify-between transition-all ${activeDetailTab === index
//                                         ? 'bg-white/20 text-white'
//                                         : 'hover:bg-white/10 text-white/70'
//                                     }`}
//                             >
//                                 {tab}
//                                 <ChevronRight size={20} />
//                             </button>
//                         ))}
//                     </div>

//                     <div className="mt-auto">
//                         <div className="flex items-center gap-3 bg-white/10 p-4 rounded-lg">
//                             <div className={`
//                                 ${currentStageIndex === 3
//                                     ? 'text-green-400'
//                                     : 'text-yellow-400'
//                                 }
//                             `}>
//                                 {currentStageIndex === 3 ? <CheckCircle size={24} /> : <Navigation size={24} />}
//                             </div>
//                             <div>
//                                 <p className="font-semibold">{latestScan.Scan}</p>
//                                 <p className="text-xs opacity-75">{formatDate(latestScan.ScanDateTime)}</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Main Content Area */}
//                 <div className="col-span-2 p-8 overflow-y-auto">
//                     {/* Progress Tracker */} 
//                     <div className="flex items-center justify-between mb-8">
//                         {progressStages.map((stage, index) => (
//                             <div
//                                 key={stage}
//                                 className="flex flex-col items-center"
//                             >
//                                 <div
//                                     className={`
//                                         w-12 h-12 rounded-full flex items-center justify-center mb-2 
//                                         ${index <= currentStageIndex
//                                             ? 'bg-blue-600 text-white'
//                                             : 'bg-gray-200 text-gray-500'
//                                         }
//                                     `}
//                                 >
//                                     {index + 1}
//                                 </div>
//                                 <p className={`
//                                     text-sm font-medium 
//                                     ${index <= currentStageIndex
//                                         ? 'text-blue-600'
//                                         : 'text-gray-400'
//                                     }
//                                 `}>
//                                     {stage}
//                                 </p>
//                             </div>
//                         ))}
//                     </div>

//                     {/* Dynamic Content Sections */}
//                     {activeDetailTab === 0 && (
//                         <div className="grid md:grid-cols-2 gap-6">
//                             <div>
//                                 <h3 className="text-xl font-semibold mb-4 border-b pb-2">Shipment Details</h3>
//                                 <div className="space-y-3">
//                                     <div className="flex items-center gap-3">
//                                         <MapPin size={20} className="text-blue-600" />
//                                         <div>
//                                             <p className="font-medium">Origin</p>
//                                             <p className="text-sm text-gray-600">{shipment.Origin}</p>
//                                         </div>
//                                     </div>
//                                     <div className="flex items-center gap-3">
//                                         <Clock size={20} className="text-blue-600" />
//                                         <div>
//                                             <p className="font-medium">Order Type</p>
//                                             <p className="text-sm text-gray-600">{shipment.OrderType}</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div>
//                                 <h3 className="text-xl font-semibold mb-4 border-b pb-2">Additional Info</h3>
//                                 <p>Latest Instructions: {latestScan.Instructions}</p>
//                             </div>
//                         </div>
//                     )}

//                     {activeDetailTab === 1 && (
//                         <div>
//                             <h3 className="text-xl font-semibold mb-4 border-b pb-2">Recipient Details</h3>
//                             <div className="grid md:grid-cols-2 gap-4">
//                                 <div>
//                                     <p><span className="font-medium">Name:</span> {shipment.Consignee.Name}</p>
//                                     <p><span className="font-medium">City:</span> {shipment.Consignee.City || 'Not specified'}</p>
//                                 </div>
//                                 <div>
//                                     <p><span className="font-medium">State:</span> {shipment.Consignee.State}</p>
//                                     <p><span className="font-medium">Pincode:</span> {shipment.Consignee.PinCode}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {activeDetailTab === 2 && (
//                         <div>
//                             <h3 className="text-xl font-semibold mb-4 border-b pb-2">Shipment Timeline</h3>
//                             <div className="space-y-4">
//                                 {shipment.Scans.slice().reverse().map((scan, index) => (
//                                     <div
//                                         key={index}
//                                         className="bg-gray-100 p-4 rounded-lg flex items-center gap-4"
//                                     >
//                                         <div className="w-10 h-10 bg-blue-600 text-white rounded-full flex items-center justify-center">
//                                             {shipment.Scans.length - index}
//                                         </div>
//                                         <div>
//                                             <p className="font-medium">{scan.ScanDetail.Scan}</p>
//                                             <p className="text-sm text-gray-600">{scan.ScanDetail.ScannedLocation}</p>
//                                             <p className="text-xs text-gray-500">
//                                                 {formatDate(scan.ScanDetail.ScanDateTime)}
//                                             </p>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     )}

//                     <div className="mt-6 flex justify-end">
//                         <button
//                             onClick={onClose}
//                             className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
//                         >
//                             Close Tracking
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default TrackShipment;   



import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import {
    Package,
    MapPin,
    Clock,
    Navigation,
    CheckCircle,
    ChevronRight,
    ChevronLeft,
    Truck,
    Home,
    Box,
    Calendar,
    MapPinned,
    Info
} from 'lucide-react';

const TrackShipment = ({ isOpen, onClose, trackData }) => {
    const [activeDetailTab, setActiveDetailTab] = useState(0);

    if (!isOpen || !trackData) return null;

    const shipment = trackData;
    const latestScan = shipment.Scans[shipment.Scans.length - 1].ScanDetail;

    const formatDate = (dateString) => {
        if (!dateString) return 'Not available';
        return new Date(dateString).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const progressStages = [
        'Shipped',
        'In Transit',
        'Out for Delivery',
        'Delivered'
    ];

    const getCurrentStageIndex = (currentStatus) => {
        const statusMap = {
            'Shipped': 0,
            'In Transit': 1,
            'Out for Delivery': 2,
            'Delivered': 3
        };
        return statusMap[currentStatus] || 0;
    };

    const getScanIcon = (scanType) => {
        switch (scanType) {
            case 'Shipped':
                return <Box className="text-blue-500" />;
            case 'In Transit':
                return <Truck className="text-amber-500" />;
            case 'Out for Delivery':
                return <Navigation className="text-orange-500" />;
            case 'Delivered':
                return <CheckCircle className="text-green-500" />;
            default:
                return <Info className="text-gray-500" />;
        }
    };

    const currentStageIndex = getCurrentStageIndex(latestScan.Scan);

    return (
        <div className="fixed inset-0 bg-black/60 flex items-center justify-center p-4 backdrop-blur-sm z-50">
            <div className="bg-white w-full max-w-5xl rounded-2xl shadow-2xl overflow-hidden grid grid-cols-3 max-h-[90vh]">
                {/* Sidebar Navigation */}
                <div className="bg-gradient-to-b from-blue-600 to-blue-800 text-white p-6 col-span-1 flex flex-col">
                    <div className="mb-8">
                        <button
                            onClick={onClose}
                            className="absolute top-4 right-4"
                        >
                            <RxCross2 size={24} className="text-black" />
                        </button>
                        <div className="bg-white/10 p-3 rounded-lg inline-flex mb-4">
                            <Package size={36} className="text-white" />
                        </div>
                        <h2 className="text-2xl font-bold">Shipment Tracking</h2>
                        <p className="text-sm opacity-75 flex items-center gap-2 mt-2">
                            <Box size={16} />
                            AWB: {shipment.AWB}
                        </p>
                    </div>

                    <div className="space-y-4 flex-grow">
                        {[
                            { name: 'Shipment', icon: <Truck size={18} /> },
                            { name: 'Recipient', icon: <Home size={18} /> },
                            { name: 'Timeline', icon: <Calendar size={18} /> }
                        ].map((tab, index) => (
                            <button
                                key={tab.name}
                                onClick={() => setActiveDetailTab(index)}
                                className={`w-full text-left p-3 rounded-lg flex items-center justify-between transition-all ${activeDetailTab === index
                                    ? 'bg-white/20 text-white'
                                    : 'hover:bg-white/10 text-white/70'
                                    }`}
                            >
                                <div className="flex items-center gap-3">
                                    {tab.icon}
                                    <span>{tab.name}</span>
                                </div>
                                <ChevronRight size={20} />
                            </button>
                        ))}
                    </div>

                    <div className="mt-auto">
                        <div className="flex items-center gap-3 bg-white/10 p-4 rounded-lg">
                            <div className={`
                                ${currentStageIndex === 3
                                    ? 'text-green-400'
                                    : 'text-yellow-400'
                                }
                            `}>
                                {currentStageIndex === 3 ? <CheckCircle size={24} /> : <Navigation size={24} />}
                            </div>
                            <div>
                                <p className="font-semibold">{latestScan.Scan}</p>
                                <p className="text-xs opacity-75">{formatDate(latestScan.ScanDateTime)}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Content Area */}
                <div className="col-span-2 p-8 overflow-y-auto">
                    {/* Progress Tracker */}
                    <div className="flex items-center justify-between mb-8">
                        {progressStages.map((stage, index) => (
                            <div
                                key={stage}
                                className="flex flex-col items-center"
                            >
                                <div
                                    className={`
                                        w-12 h-12 rounded-full flex items-center justify-center mb-2 
                                        ${index <= currentStageIndex
                                            ? 'bg-blue-600 text-white'
                                            : 'bg-gray-200 text-gray-500'
                                        }
                                    `}
                                >
                                    {index + 1}
                                </div>
                                <p className={`
                                    text-sm font-medium 
                                    ${index <= currentStageIndex
                                        ? 'text-blue-600'
                                        : 'text-gray-400'
                                    }
                                `}>
                                    {stage}
                                </p>
                            </div>
                        ))}
                    </div>

                    {/* Dynamic Content Sections */}
                    {activeDetailTab === 0 && (
                        <div className="grid md:grid-cols-2 gap-6">
                            <div className="bg-blue-50 p-6 rounded-xl">
                                <h3 className="text-xl font-semibold mb-4 border-b border-blue-100 pb-2 flex items-center gap-2">
                                    <Truck size={20} className="text-blue-600" />
                                    Shipment Details
                                </h3>
                                <div className="space-y-4">
                                    <div className="flex items-center gap-3">
                                        <div className="bg-blue-100 p-2 rounded-lg">
                                            <MapPin size={20} className="text-blue-600" />
                                        </div>
                                        <div>
                                            <p className="font-medium">Origin</p>
                                            <p className="text-sm text-gray-600">{shipment.Origin}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="bg-blue-100 p-2 rounded-lg">
                                            <Clock size={20} className="text-blue-600" />
                                        </div>
                                        <div>
                                            <p className="font-medium">Order Type</p>
                                            <p className="text-sm text-gray-600">{shipment.OrderType}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-green-50 p-6 rounded-xl">
                                <h3 className="text-xl font-semibold mb-4 border-b border-green-100 pb-2 flex items-center gap-2">
                                    <Info size={20} className="text-green-600" />
                                    Additional Info
                                </h3>
                                <p>Latest Instructions: {latestScan.Instructions || 'No special instructions'}</p>
                            </div>
                        </div>
                    )}

                    {activeDetailTab === 1 && (
                        <div className="bg-purple-50 p-6 rounded-xl">
                            <h3 className="text-xl font-semibold mb-4 border-b border-purple-100 pb-2 flex items-center gap-2">
                                <Home size={20} className="text-purple-600" />
                                Recipient Details
                            </h3>
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className="space-y-3">
                                    <div className="flex items-center gap-3">
                                        <div className="bg-purple-100 p-2 rounded-lg">
                                            <Package size={18} className="text-purple-600" />
                                        </div>
                                        <div>
                                            <p className="text-sm text-purple-700">Name</p>
                                            <p className="font-medium">{shipment.Consignee.Name}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="bg-purple-100 p-2 rounded-lg">
                                            <MapPinned size={18} className="text-purple-600" />
                                        </div>
                                        <div>
                                            <p className="text-sm text-purple-700">City</p>
                                            <p className="font-medium">{shipment.Consignee.City || 'Not specified'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-3">
                                    <div className="flex items-center gap-3">
                                        <div className="bg-purple-100 p-2 rounded-lg">
                                            <MapPin size={18} className="text-purple-600" />
                                        </div>
                                        <div>
                                            <p className="text-sm text-purple-700">State</p>
                                            <p className="font-medium">{shipment.Consignee.State}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="bg-purple-100 p-2 rounded-lg">
                                            <Box size={18} className="text-purple-600" />
                                        </div>
                                        <div>
                                            <p className="text-sm text-purple-700">Pincode</p>
                                            <p className="font-medium">{shipment.Consignee.PinCode}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeDetailTab === 2 && (
                        <div>
                            <h3 className="text-xl font-semibold mb-4 border-b pb-2 flex items-center gap-2">
                                <Calendar size={20} className="text-blue-600" />
                                Shipment Timeline
                            </h3>
                            <div className="max-h-64  pr-2 overflow-y-auto  scrollbar-thin scrollbar-thumb-blue-200 scrollbar-track-gray-100">
                                <div className="relative">
                                    <div className="absolute top-0 bottom-0 left-5 w-0.5 bg-blue-200"></div>
                                    <div className="space-y-4">
                                        {shipment.Scans.slice().reverse().map((scan, index) => (
                                            <div
                                                key={index}
                                                className="bg-gray-50 hover:bg-blue-50 p-4 rounded-lg flex items-center gap-4 relative transition-colors border border-gray-100"
                                            >
                                                <div className="w-10 h-10 bg-white text-blue-600 rounded-full flex items-center justify-center border-2 border-blue-200 z-10">
                                                    {getScanIcon(scan.ScanDetail.Scan)}
                                                </div>
                                                <div className="flex-1">
                                                    <p className="font-medium text-blue-800">{scan.ScanDetail.Scan}</p>
                                                    <div className="flex items-center gap-2 text-sm text-gray-600">
                                                        <MapPin size={14} className="text-gray-400" />
                                                        {scan.ScanDetail.ScannedLocation || 'Location not available'}
                                                    </div>
                                                    <div className="flex items-center gap-2 text-xs text-gray-500 mt-1">
                                                        <Clock size={12} />
                                                        {formatDate(scan.ScanDetail.ScanDateTime)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={onClose}
                            className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
                        >
                            <RxCross2 size={18} />
                            Close Tracking
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackShipment;