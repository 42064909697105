export const Base_Url = process.env.REACT_APP_BASE_URL;
 
export const Url_For_View_Document = process.env.REACT_APP_VIEW_DOCUMENT_URL;

 export const  priceEstimator = "public/price-estimator"

 export const getpincode = "/public/getpincode"
 
 export const trackShipment = "/public/track"

