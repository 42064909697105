import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { BrowserRouter as Routes, Route } from "react-router-dom";
import Privacypolicy from "../Pages/Privacypolicy";
import { Link } from "react-router-dom";
import logo from "../assets/logo1.png";

const Footer = () => {
  return (
    <footer
      className="w-full mt-10 max-w-screen-2xl mx-auto bg-transparent shadow-2xl rounded-lg overflow-hidden flex 
    flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200"
    >
      <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8">
        <div className=" lg:items-start lg:gap-8">
          <div className="text-sky-600 h-18 w-80">
            <a
              href="#"
              class="block cursor-pointer py-1.5 font-sans text-4xl font-bold leading-relaxed text-black antialiased"
            >
              {/* PICKUPXPRESS<sup>®</sup> */}
              <img className="mx-auto rounded-xl mr-[60px]" src={logo} width="270" height="" alt="Logo" />
            </a>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16">
            <div className="col-span-2">
              <div>
                <h2 className="text-2xl font-bold text-sky-800">
                  Get Connected With Us!
                </h2>

                <p className="mt-4 text-sky-600 text-justify">
                  PICKUPXPRESS is your one-stop shop for all your shipping
                  needs. We offer a comprehensive suite of services to ensure
                  your parcels, ecommerce orders, and cargo reach their
                  destinations efficiently and securely.
                </p>
                <p className="text-sky-800 text-[10px] antialiased pt-4">
                  ISO 9001: 2015, Certified Company CIN: UP63030UP2021PTC141702
                </p>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-3 lg:flex lg:items-end gap-10">
              <form className="w-full">
                <label htmlFor="UserEmail" className="sr-only">
                  {" "}
                  Email{" "}
                </label>

                <div className="border border-sky-600 rounded-md focus-within:ring sm:flex sm:items-center sm:gap-4">
                  <input
                    type="email"
                    id="UserEmail"
                    placeholder="username@gmail.com"
                    className="w-full h-full border-none px-8 font-medium text-lg focus:border-transparent focus:ring-transparent sm:text-sm"
                  />

                  <button className="mt-1 right-0 top-0 bg-sky-600 rounded-md px-6 py-3 text-sm font-bold uppercase tracking-wide text-white transition-none hover:bg-sky-400 sm:mt-0 sm:shrink-0">
                    Sign Up
                  </button>
                </div>
              </form>
            </div>

            <div className="col-span-2 sm:col-span-1">
              <p className="font-medium text-sky-800">Services</p>

              <ul className="mt-6 space-y-4 text-sm">
                <li>
                  <a
                    href="/Express"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Express Shipment{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Surfaceship"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Surface Shipment{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Truckload"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Full Truck Load{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Traincargo"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Train Cargo{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/ExportImp"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Import Export{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/Ecommerce"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    Ecommerce Shippings
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-span-2 sm:col-span-1">
              <p className="font-medium text-sky-800">Company</p>

              <ul className="mt-6 space-y-4 text-sm">
                <li>
                  <a
                    href="/Aboutus"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    About Us{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Career"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    Careers
                  </a>
                </li>

                {/* <li>
                  <a
                    href="#"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Press & Media{" "}
                  </a>
                </li> */}
                <li>
                  <a
                    href="/Contactus"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="/Privacypolicy"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/Tandc"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Terms & Conditions{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-span-2 sm:col-span-1">
              <p className="font-medium text-sky-800">Helpful Links</p>

              <ul className="mt-6 space-y-4 text-sm">
                <li>
                  <a
                    href="#"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Packaging Guide{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Contactus#faq-section"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    FAQs{" "}
                  </a>
                </li>

                {/* <li>
                  <a
                    href="#"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Live Chat{" "}
                  </a>
                </li> */}
              </ul>
            </div>

            <div className="col-span-2 sm:col-span-1">
              <p className="font-medium text-sky-800">Legal</p>

              <ul className="mt-6 space-y-4 text-sm">
                <li>
                  <a
                    href="/Accessibility"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Accessibility{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Refundpolicy"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Refund Policy{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Cancellation"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Cancellation Policy{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/Csr"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Corporate Social Responsibility{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-span-2 sm:col-span-1">
              <p className="font-medium text-sky-800">Downloads</p>

              <ul className="mt-6 space-y-4 text-sm">
                <li>
                  <a
                    href="#"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Andriod App
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Apple App
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Company Profile
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sky-700 transition hover:opacity-75"
                  >
                    {" "}
                    Financial Reports
                  </a>
                </li>
              </ul>
            </div>

            <ul className="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end">
              <li>
                <a
                  href="https://www.facebook.com/pxpickupxpress?_rdr"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Facebook</span>

                  <svg
                    className="size-6 text-sky-700"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/pickupxpress/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Instagram</span>
                  <svg
                    className="size-6 text-sky-700"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Twitter</span>

                  <svg
                    className="size-6 text-sky-700"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://wa.me/918707000087?text=Thankyou For connecting with us!"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75 w-36 h-36"
                >
                  <span className="sr-only">WhatsApp</span>

                  <div className="size-6 text-sky-700 text-xl ">
                    <IoLogoWhatsapp />
                  </div>
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/company/pickupxpress/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">LinkedIn</span>

                  <div className="size-6 text-sky-700 text-xl">
                    <FaLinkedin />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-100 pt-8">
          <div className="sm:flex sm:justify-between">
            <p className="text-xs text-sky-700">
              &copy; 2024. Olbuiz Logistis Pvt. Ltd. All rights reserved.
            </p>

            <ul className="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end">
              <li>
                <a
                  href="/Tandc"
                  className="text-sky-900 transition hover:opacity-75"
                >
                  {" "}
                  Terms & Conditions{" "}
                </a>
              </li>

              <li className="text-sky-900 transition hover:opacity-75">
                <a
                  href="/Privacypolicy"
                  className="text-sky-900 transition hover:opacity-75"
                >
                  {" "}
                  Privacy Policy{" "}
                </a>{" "}
              </li>
              <li>
                <a
                  href="/Cookies"
                  className="text-sky-900 transition hover:opacity-75"
                >
                  {" "}
                  Cookies{" "}
                </a>
              </li>
            </ul>
            {/*<Routes>
              <Route path="Privacypolicy" element={<Privacypolicy />} />
            </Routes> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;







// import React from "react";
// import { IoLogoWhatsapp } from "react-icons/io";
// import { FaLinkedin } from "react-icons/fa";
// import { BrowserRouter as Routes, Route } from "react-router-dom";
// import Privacypolicy from "../Pages/Privacypolicy";
// import { Link } from "react-router-dom";

// const Footer = () => {
//   return (
//     <footer
//       className="w-full mt-10 max-w-screen-2xl mx-auto bg-transparent shadow-2xl rounded-lg overflow-hidden flex 
//     flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200"
//     >
//       <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8">
//         <div className=" lg:items-start lg:gap-8">
//           <div className="text-sky-600 h-18 w-80">
//             <a
//               href="#"
//               class="block cursor-pointer py-1.5 font-sans text-4xl font-bold leading-relaxed text-black antialiased"
//             >
//               PICKUPXPRESS<sup>®</sup>
//             </a>
//           </div>
//           <div className="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16">
//             <div className="col-span-2">
//               <div>
//                 <h2 className="text-2xl font-bold text-sky-800">
//                   Get Connected With Us!
//                 </h2>

//                 <p className="mt-4 text-sky-600 text-justify">
//                   PICKUPXPRESS is your one-stop shop for all your shipping
//                   needs. We offer a comprehensive suite of services to ensure
//                   your parcels, ecommerce orders, and cargo reach their
//                   destinations efficiently and securely.
//                 </p>
//                 <p className="text-sky-800 text-[10px] antialiased pt-4">
//                   ISO 9001: 2015, Certified Company CIN: UP63030UP2021PTC141702
//                 </p>
//               </div>
//             </div>

//             <div className="col-span-2 lg:col-span-3 lg:flex lg:items-end gap-10">
//               <form className="w-full">
//                 <label htmlFor="UserEmail" className="sr-only">
//                   {" "}
//                   Email{" "}
//                 </label>

//                 <div className="border border-sky-600 rounded-md focus-within:ring sm:flex sm:items-center sm:gap-4">
//                   <input
//                     type="email"
//                     id="UserEmail"
//                     placeholder="username@gmail.com"
//                     className="w-full h-full border-none px-8 font-medium text-lg focus:border-transparent focus:ring-transparent sm:text-sm"
//                   />

//                   <button className="mt-1 right-0 top-0 bg-sky-600 rounded-md px-6 py-3 text-sm font-bold uppercase tracking-wide text-white transition-none hover:bg-sky-400 sm:mt-0 sm:shrink-0">
//                     Sign Up
//                   </button>
//                 </div>
//               </form>
//             </div>

//             <div className="col-span-2 sm:col-span-1">
//               <p className="font-medium text-sky-800">Services</p>

//               <ul className="mt-6 space-y-4 text-sm">
//                 <li>
//                   <a
//                     href="/Express"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Express Shipment{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Surfaceship"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Surface Shipment{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Truckload"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Full Truck Load{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Traincargo"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Train Cargo{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/ExportImp"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Import Export{" "}
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="/Ecommerce"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     Ecommerce Shippings
//                   </a>
//                 </li>
//               </ul>
//             </div>

//             <div className="col-span-2 sm:col-span-1">
//               <p className="font-medium text-sky-800">Company</p>

//               <ul className="mt-6 space-y-4 text-sm">
//                 <li>
//                   <a
//                     href="/Aboutus"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     About Us{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Career"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     Careers
//                   </a>
//                 </li>

//                 {/* <li>
//                   <a
//                     href="#"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Press & Media{" "}
//                   </a>
//                 </li> */}
//                 <li>
//                   <a
//                     href="/Contactus"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     Contact Us
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="/Privacypolicy"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Privacy Policy{" "}
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="/Tandc"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Terms & Conditions{" "}
//                   </a>
//                 </li>
//               </ul>
//             </div>

//             <div className="col-span-2 sm:col-span-1">
//               <p className="font-medium text-sky-800">Helpful Links</p>

//               <ul className="mt-6 space-y-4 text-sm">
//                 <li>
//                   <a
//                     href="#"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Packaging Guide{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Contactus#faq-section"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     FAQs{" "}
//                   </a>
//                 </li>

//                 {/* <li>
//                   <a
//                     href="#"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Live Chat{" "}
//                   </a>
//                 </li> */}
//               </ul>
//             </div>

//             <div className="col-span-2 sm:col-span-1">
//               <p className="font-medium text-sky-800">Legal</p>

//               <ul className="mt-6 space-y-4 text-sm">
//                 <li>
//                   <a
//                     href="/Accessibility"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Accessibility{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Refundpolicy"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Refund Policy{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Cancellation"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Cancellation Policy{" "}
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="/Csr"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Corporate Social Responsibility{" "}
//                   </a>
//                 </li>
//               </ul>
//             </div>

//             <div className="col-span-2 sm:col-span-1">
//               <p className="font-medium text-sky-800">Downloads</p>

//               <ul className="mt-6 space-y-4 text-sm">
//                 <li>
//                   <a
//                     href="#"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Andriod App
//                   </a>
//                 </li>

//                 <li>
//                   <a
//                     href="#"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Apple App
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="#"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Company Profile
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="#"
//                     className="text-sky-700 transition hover:opacity-75"
//                   >
//                     {" "}
//                     Financial Reports
//                   </a>
//                 </li>
//               </ul>
//             </div>

//             <ul className="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end">
//               <li>
//                 <a
//                   href="https://www.facebook.com/pxpickupxpress?_rdr"
//                   rel="noreferrer"
//                   target="_blank"
//                   className="text-gray-700 transition hover:opacity-75"
//                 >
//                   <span className="sr-only">Facebook</span>

//                   <svg
//                     className="size-6 text-sky-700"
//                     fill="currentColor"
//                     viewBox="0 0 24 24"
//                     aria-hidden="true"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
//                       clipRule="evenodd"
//                     />
//                   </svg>
//                 </a>
//               </li>

//               <li>
//                 <a
//                   href="https://www.instagram.com/pickupxpress/"
//                   rel="noreferrer"
//                   target="_blank"
//                   className="text-gray-700 transition hover:opacity-75"
//                 >
//                   <span className="sr-only">Instagram</span>
//                   <svg
//                     className="size-6 text-sky-700"
//                     fill="currentColor"
//                     viewBox="0 0 24 24"
//                     aria-hidden="true"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
//                       clipRule="evenodd"
//                     />
//                   </svg>
//                 </a>
//               </li>

//               <li>
//                 <a
//                   href="#"
//                   rel="noreferrer"
//                   target="_blank"
//                   className="text-gray-700 transition hover:opacity-75"
//                 >
//                   <span className="sr-only">Twitter</span>

//                   <svg
//                     className="size-6 text-sky-700"
//                     fill="currentColor"
//                     viewBox="0 0 24 24"
//                     aria-hidden="true"
//                   >
//                     <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
//                   </svg>
//                 </a>
//               </li>

//               <li>
//                 <a
//                   href="https://wa.me/918707000087?text=Thankyou For connecting with us!"
//                   rel="noreferrer"
//                   target="_blank"
//                   className="text-gray-700 transition hover:opacity-75 w-36 h-36"
//                 >
//                   <span className="sr-only">WhatsApp</span>

//                   <div className="size-6 text-sky-700 text-xl ">
//                     <IoLogoWhatsapp />
//                   </div>
//                 </a>
//               </li>

//               <li>
//                 <a
//                   href="https://www.linkedin.com/company/pickupxpress/"
//                   rel="noreferrer"
//                   target="_blank"
//                   className="text-gray-700 transition hover:opacity-75"
//                 >
//                   <span className="sr-only">LinkedIn</span>

//                   <div className="size-6 text-sky-700 text-xl">
//                     <FaLinkedin />
//                   </div>
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>

//         <div className="mt-8 border-t border-gray-100 pt-8">
//           <div className="sm:flex sm:justify-between">
//             <p className="text-xs text-sky-700">
//               &copy; 2024. Olbuiz Logistis Pvt. Ltd. All rights reserved.
//             </p>

//             <ul className="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end">
//               <li>
//                 <a
//                   href="/Tandc"
//                   className="text-sky-900 transition hover:opacity-75"
//                 >
//                   {" "}
//                   Terms & Conditions{" "}
//                 </a>
//               </li>

//               <li className="text-sky-900 transition hover:opacity-75">
//                 <a
//                   href="/Privacypolicy"
//                   className="text-sky-900 transition hover:opacity-75"
//                 >
//                   {" "}
//                   Privacy Policy{" "}
//                 </a>{" "}
//               </li>
//               <li>
//                 <a
//                   href="/Cookies"
//                   className="text-sky-900 transition hover:opacity-75"
//                 >
//                   {" "}
//                   Cookies{" "}
//                 </a>
//               </li>
//             </ul>
//             {/*<Routes>
//               <Route path="Privacypolicy" element={<Privacypolicy />} />
//             </Routes> */}
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

