import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed bottom-5 right-5">
      {isVisible && (
        <div className="group relative">
          <div className="absolute bottom-10 right-10 transform translate-x-1/2 opacity-0 group-hover:opacity-100 group-hover:translate-y-[-10px] transition-all duration-300 ease-in-out bg-sky-600  text-white text-sm font-semibold rounded-lg p-2 shadow-md min-w-[100px] max-w-[150px] text-center">
            Back to Top
          </div>

          <button
            onClick={scrollToTop}
            className="bg-sky-600 text-white p-3 rounded-full hover:shadow-lg hover:bg-sky-500 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-400"
          >
            <FaArrowUp />
          </button>
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
