import React from "react";
import { TfiPackage } from "react-icons/tfi";
import { CiShoppingCart } from "react-icons/ci";
import { IoIosPeople } from "react-icons/io";
import { IoBusinessOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const services = [
  {
    title: "B2C Orders",
    description:
      "Customized fulfillment services for all your B2C orders with seamless delivery options.",
    icon: <CiShoppingCart className="w-12 h-12 text-sky-600" />,
    link: "/Smallpack",
  },
  {
    title: "D2C Orders",
    description:
      "Direct-to-consumer (D2C) order fulfillment services tailored to your business needs.",
    icon: <TfiPackage className="w-12 h-12 text-sky-600" />,
    link: "/d2c-orders",
  },
  {
    title: "Personal Couriers",
    description:
      "Reliable and fast courier services for personal shipments, ensuring safe delivery.",
    icon: <IoIosPeople className="w-12 h-12 text-sky-600" />,
    link: "/personal-couriers",
  },
  {
    title: "B2B Orders",
    description:
      "Specialized B2B order fulfillment services designed for bulk and business shipments.",
    icon: <IoBusinessOutline className="w-12 h-12 text-sky-600" />,
    link: "/Express",
  },
];

const Features = () => {
  return (
    <div
      className="w-full mt-10 max-w-screen-xl mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden flex 
    flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 pb-8 pt-6"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
          Our Customized Fulfillment Services
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center transform hover:scale-105 transition-transform duration-300"
            >
              {service.icon}
              <h3 className="mt-4 text-xl font-semibold text-gray-800">
                {service.title}
              </h3>
              <p className="mt-2 text-gray-600">{service.description}</p>
             
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
