// src/components/CookieConsentPopup.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Cookies = () => {
  debugger
  const [visible, setVisible] = useState(false);

  // Show popup only if cookies haven't been accepted/rejected yet
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Store cookie consent in localStorage and hide the popup
    localStorage.setItem("cookiesAccepted", "true");
    setVisible(false);
    console.log("Cookies accepted");
  };

  const handleReject = () => {
    // Store cookie rejection in localStorage and hide the popup
    localStorage.setItem("cookiesAccepted", "false");
    setVisible(false);
    console.log("Cookies rejected");
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-sky-600 text-white py-4 px-6 flex justify-between items-center z-50">
      <div className="text-sm">
        We use cookies to enhance your experience. By continuing, you agree to
        our
        <Link to="/cookies-policy" className="underline ml-1">
          Cookies Policy
        </Link>
        .
      </div>
      <div>
        <button
          onClick={handleAccept}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Accept
        </button>
        <button
          onClick={handleReject}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default Cookies;
