import React from 'react';
import CardTesti from '../Cards/CardTesti';
import Services from '../Cards/Services';
import CardCustomf from '../Cards/CardCustomf';
import CardPlat from '../Cards/CardPlat';
import Features from '../Cards/Features';
import CardSer from '../Cards/CardSer';
import CardPart from '../Cards/CardPart';
import CardCustomer from '../Cards/CardCustomer';


const Home = () => {
  return (
    <div>
      <CardTesti />
      <Services />
      <CardCustomf />
      <CardPlat />
      <Features />
      <CardSer />
      <CardPart />
      <CardCustomer />
    </div>
  );
};

export default Home;
