import React from "react";
import shopify from "../assets/shopify.svg";
import Woo from "../assets/Woo.svg";
import gs from "../assets/gs.png";
import isis from "../assets/isis.png";
import magento from "../assets/magento.svg";
import amasis from "../assets/amasis.svg";
import prestashops from "../assets/prestashops.svg";
import ocart from "../assets/ocart.svg";

const platforms = [
  { name: "Shopify", logo: shopify },
  { name: "Instamojo", logo: isis, size: "large" },
  { name: "Unicommerce", logo: gs, size: "large" },
  { name: "Woocommerce", logo: Woo, size: "large" },
  { name: "Magento", logo: magento },
  { name: "gs", logo: amasis },
  { name: "gs", logo: prestashops },
  { name: "gs", logo: ocart },
];

const CardPlat = () => {
  return (
    <div className="w-full mt-10 max-w-screen-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 py-12">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-sky-600">
          Forget to Download Order? Sync with Easy Integration process
        </h2>
        <p className="text-lg text-center mb-8 text-sky-700">
          Seamlessly integrate with major platforms like Shopify, WooCommerce,
          Magento, Instamojo, PrestaShop, UniCommerce, Amazon, OpenCart, and
          more.
        </p>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
          {platforms.map((platform, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center bg-sky-600 p-6 rounded-lg shadow-lg"
            >
              <img
                src={platform.logo}
                alt={platform.name}
                className={`${
                  platform.size === "large" ? "w-32" : "w-24"
                } h-auto mb-0 cursor-pointer`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardPlat;
